
//  PRODUCTION
// export const apiHost = "https://api.tpmechanisms.com";

//  PRE PRODUCTION
// export const apiHost = "https://preprod.api.tpmechanisms.com";

//  TESTING
export const apiHost = "https://api.moc.tpmechanisms.com";
//  DEV
// export const apiHost = "https://192.168.2.22:5001";


export const apiURL = `${apiHost}/api/v1`;

export const qrCodeApi = (id: string) => `https://api.qrserver.com/v1/create-qr-code/?data=${id}&size=[400]x[400]&format=svg`;