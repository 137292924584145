import  React, { useState } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import  {horizontalMenuConfig as navigationConfig }  from "../../../../../configs/horizontalMenuConfig"
import SideMenuGroup from "./SideMenuGroup"
import { Badge } from "reactstrap"
import { ChevronRight } from "react-feather"
import { history } from "../../../../../history"
import { contains, includes, prop } from "ramda"
import { useStore } from "../../../../../stores/index"
import { observer } from "mobx-react-lite"
const SideMenuContent = observer((props)=> {
  const {
    accountStore: {
      currentUser,
      roleRights,
      loading: {
        currentUser: { itemLoading },
        right : {listLoading}
      },
    },
  } = useStore();
    const parentArr = []
    const collapsedPath = null
    const redirectUnauthorized = () => {
      history.push("/misc/not-authorized")
    }
  const [sidebarProperties, setSidebarProperties] = useState(
    {
      flag: true,
      isHovered: false,
      activeGroups: [],
      currentActiveGroup: [],
      tempArr: []
    }
  )
  const accessAllowed = (custAccess, user) => {
    if (!user) return false;
    const hasCutomerID = user.customerId !== "" && user.customerId;
    if (hasCutomerID) return contains("customer", custAccess);
    return contains("productOwner", custAccess);
  };
 const  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = sidebarProperties.activeGroups
    let active_group = sidebarProperties.currentActiveGroup
    let temp_arr = sidebarProperties.tempArr
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = []
      temp_arr = []
    } else if (type === "item" && parent !== null) {
      active_group = []
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length)
      } else {
        temp_arr = []
        temp_arr.push(parent)
      }
      active_group = temp_arr.slice(0)
    } else if (type === "dropdown" && parent === null) {
      temp_arr = []
      temp_arr.push(id)
    } else if (type === "dropdown" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0)
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length)
      } else {
        temp_arr.push(id)
      }
    } else {
      temp_arr = []
    }
    if (type === "dropdown") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function(obj) {
          return active_group.indexOf(obj) === -1
        })
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function(obj) {
            return !temp.includes(obj)
          })
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0)
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id)
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1)
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0)
    }
    setSidebarProperties({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group
    })
  }
  const initRender = (parentArr) => {
    setSidebarProperties({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false
    })
  }
    // Loop over sidebar items
    // eslint-disable-next-line
    const menuItems =!(itemLoading || listLoading ) &&  navigationConfig.filter((x) =>
    accessAllowed(x.customAccess, currentUser)
  ).map(item => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link
      // checks if item has groupheader
      if (item.type === "groupHeader") {
        return (
          <li
            className="navigation-header"
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        )
      }
      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "dropdown",
            open: sidebarProperties.activeGroups.includes(item.id),
            "sidebar-group-active": sidebarProperties.currentActiveGroup.includes(
              item.id
            ),
            hover: props.hoverIndex === item.id,
            active:
              (props.activeItemState === item.navLink &&
              item.type === "item" )|| (item.parentOf && item.parentOf.includes(props.activeItemState)),
            disabled: item.disabled
          })}
          key={item.id}
          onClick={e => {
            e.stopPropagation()
            if (item.type === "item") {
              props.handleActiveItem(item.navLink)
              handleGroupClick(item.id, null, item.type)
              if(props.deviceWidth <= 1200 && item.type === "item"){
                props.toggleMenu()
              }
            } else {
              handleGroupClick(item.id, null, item.type)
            }
          }}
        >
          <CustomAnchorTag
            to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink :  ""}
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${
              item.badgeText
                ? "justify-content-between"
                : "justify-content-start"
            }`}
            onMouseEnter={() => {
              props.handleSidebarMouseEnter(item.id)
            }}
            onMouseLeave={() => {
              props.handleSidebarMouseEnter(item.id)
            }}
            key={item.id}
            onClick={e => {
              return item.type === "dropdown" ? e.preventDefault() : ""
            }}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text">
              {item.icon}
              <span className="menu-item menu-title">{item.title}</span>
            </div>
            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {item.type === "dropdown" ? (
              <ChevronRight className="menu-toggle-icon" size={13} />
            ) : (
              ""
            )}
          </CustomAnchorTag>
          {item.type === "dropdown" ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={handleGroupClick}
              activeGroup={sidebarProperties.activeGroups}
              handleActiveItem={props.handleActiveItem}
              activeItemState={props.activeItemState}
              handleSidebarMouseEnter={props.handleSidebarMouseEnter}
              activePath={props.activePath}
              hoverIndex={props.hoverIndex}
              initRender={initRender}
              parentArr={parentArr}
              triggerActive={undefined}
              currentActiveGroup={sidebarProperties.currentActiveGroup}
              permission={props.permission}
              currentUser={props.currentUser}
              redirectUnauthorized={redirectUnauthorized}
              collapsedMenuPaths={props.collapsedMenuPaths}
              toggleMenu={props.toggleMenu}
              deviceWidth={props.deviceWidth}
            />
          ) : (
            ""
          )}
        </li>
      )
      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        collapsedPath = item.navLink
        props.collapsedMenuPaths(item.navLink)
      }
      if (
        item.type === "dropdown" || item.type === "external-link" ||
        (item.type === "item" &&
          item.permissions &&
          item.permissions.includes(props.currentUser)) ||
        item.permissions === undefined
      ) {
        const hasRight =
        !item.rightId ? true : roleRights.findIndex((x) => x.id.toLowerCase() === item.rightId.toLowerCase()) !== -1;
        return hasRight && renderItem;
      } else if (
        item.type === "item" &&
        item.navLink === props.activePath &&
        !item.permissions.includes(props.currentUser)
      ) {
        return redirectUnauthorized()
      }
    })
    return <React.Fragment>{menuItems}</React.Fragment>
  });
export default SideMenuContent
