import { convertDateTimeToUtcDate } from "./../utility/Common";
import { observable, flow, computed } from "mobx";
import * as api from "../utility/dm.api";

class ReportStore {
  @observable errorMessage: string | null = null;

  @observable dashboardTotalMocStatusDetailReportData: MocStatusReportResponse[] = [];
  @observable getDashboardTotalMocStatusDetailReportLoading: boolean = false;
  getDashboardTotalMocStatusDetailReport = flow(function* (
    this: ReportStore,
    payload: DashBoardReportsPaylod
  ) {
    this.getDashboardTotalMocStatusDetailReportLoading = true;

    this.dashboardTotalMocStatusDetailReportData = [];
    try {
      const response: APIResponse<MocStatusReportResponse[]> = yield api.getDashboardTotalMocStatusDetailReport(payload);
      this.dashboardTotalMocStatusDetailReportData = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalMocStatusDetailReportLoading = false;
    }
  }).bind(this);

  changeDateTimeUTC(date: string): string {
    return convertDateTimeToUtcDate(date);
  }
}

export default ReportStore;
