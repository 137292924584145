import React from "react";
import * as Icon from "react-feather";
import { EnumAppRights } from "utility/Common";
import Home from "assets/img/navigation-icons/Home.svg";
import Teams from "assets/img/navigation-icons/Teams.svg";
import MocConfig from "assets/img/navigation-icons/MOC-Configuration.svg";
import ChangeCategory from "assets/img/navigation-icons/Category.svg";
import ChangeDepartment from "assets/img/navigation-icons/Departments.svg";
import Considerations from "assets/img/navigation-icons/Considerations.svg";
import Mandates from "assets/img/navigation-icons/Mandates.svg";
import Mocs from "assets/img/navigation-icons/MOCs.svg";
import Actions from "assets/img/navigation-icons/Actions.png";
import Configuration from "assets/img/navigation-icons/Configuration.svg";
import User from "assets/img/navigation-icons/User.svg";
import Roles from "assets/img/navigation-icons/Roles.svg";
import Branches from "assets/img/navigation-icons/Branches.svg";
import Section from "assets/img/navigation-icons/Section.svg";
import SubSection from "assets/img/navigation-icons/Sub-Section.svg";
import Asset from "assets/img/navigation-icons/Asset.svg";
import Equipment from "assets/img/navigation-icons/Equipment.svg";
import EquipmentTypes from "assets/img/navigation-icons/Equipment-types.svg";

export const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <img src={Home} alt="Home" width={20} height={20}/>,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner", "customer"],
    navLink: "/",
    rightId: EnumAppRights.Dashboard,
  },
  {
    id: "packages",
    title: "Package",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner"],
    navLink: "/packages",
  },
  {
    id: "customers",
    title: "Customer",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner"],
    navLink: "/customers",
  },
  {
    id: "subscriptions",
    title: "Subscriptions",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    customAccess: ["productOwner"],
    navLink: "/subscriptions",
  },
  {
    id: "teams",
    title: "Teams",
    type: "item",
    icon: <img src={Teams} alt="Teams" width={20} height={20}/>,
    customAccess: ["customer"],
    navLink: "/teams",
  },
  {
    id: "mocsconfig",
    title: "MOC Config",
    type: "dropdown",
    icon: <img src={MocConfig} alt="MocConfig" width={20} height={20}/>,
    customAccess: ["customer"],
    children: [
      {
        id: "changecategory",
        title: "Change Category",
        type: "item",
        icon: <img src={ChangeCategory} alt="ChangeCategory" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/changecategory",
        rightId: EnumAppRights.BranchManagement,
      },
      {
        id: "consideration",
        title: "Consideration",
        type: "item",
        icon: <img src={Considerations} alt="Considerations" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/consideration",
        rightId: EnumAppRights.BranchManagement,
      },
      {
        id: "mandates",
        title: "Mandates",
        type: "item",
        icon: <img src={Mandates} alt="Mandates" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/mandates",
        rightId: EnumAppRights.BranchManagement,
      },
    ]
  },
  {
    id: "mocs",
    title: "MOCs",
    type: "item",
    icon: <img src={Mocs} alt="mocs" width={20} height={20}/>,
    customAccess: ["customer"],
    navLink: "/mocs",
  },
  {
    id: "actions",
    title: "Actions",
    type: "item",
    icon: <img src={Actions} alt="Actions" width={20} height={20}/>,
    customAccess: ["customer"],
    navLink: "/mocconsidrationsactions",
    rightId: EnumAppRights.EquipmentManagement,
  },
  {
    id: "configure",
    title: "Configure",
    type: "dropdown",
    icon: <img src={Configuration} alt="config" width={20} height={20}/>,
    customAccess: ["productOwner", "customer"],
    children: [
      {
        id: "users",
        title: "User",
        type: "item",
        icon: <img src={User} alt="user" width={20} height={20}/>,
        customAccess: ["productOwner", "customer"],
        navLink: "/users",
      },
      {
        id: "rolesrights",
        title: "Roles & Rights",
        type: "item",
        icon: <img src={Roles} alt="Roles" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/rolesrights",
        rightId: EnumAppRights.RolesRightsManagement,
      },
      {
        id: "branches",
        title: "Branch",
        type: "item",
        icon: <img src={Branches} alt="branch" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/branches",
        rightId: EnumAppRights.BranchManagement,
      },
      {
        id: "departments",
        title: "Departments",
        type: "item",
        icon: <img src={ChangeDepartment} alt="ChangeDepartment" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/departments",
        rightId: EnumAppRights.BranchManagement,
      },
      {
        id: "subsections",
        title: "Sub Section",
        type: "item",
        icon: <img src={SubSection} alt="Sub Section" width={20} height={20}/>,
        customAccess: ["customer"],
        navLink: "/subsections",
        rightId: EnumAppRights.SubSectionsManagement,
      },
      {
        id: "assets",
        title: "Asset",
        type: "item",
        icon: <img src={Asset} alt="asset" style={{ height: "20px" }} />,
        customAccess: ["customer"],
        navLink: "/assets",
        rightId: EnumAppRights.AssetManagement,
      },
      {
        id: "equipment",
        title: "Equipment",
        type: "item",
        icon: <img src={Equipment} alt="equipment" width={18} height={20} />,
        customAccess: ["customer"],
        navLink: "/equipments",
        rightId: EnumAppRights.EquipmentManagement,
      },
      {
        id: "equipmentTypes",
        title: "Equipment Types",
        type: "item",
        icon: <img src={EquipmentTypes} alt="asset" width={18} height={20} />,
        customAccess: ["customer"],
        navLink: "/equipmenttypes",
        rightId: EnumAppRights.EquipmentManagement,
      }
    ],
  },
];