import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

const getReportURL = (url: string): string => {
  return url;
};

export const getDashboardTotalMocStatusDetailReport = (
  payload: DashBoardReportsPaylod
): Promise<APIResponse<MocStatusReportResponse[]>> =>
  request
    .post(`${apiURL}/Report/getmocbystatus`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));
