import * as request from "superagent";
import { errorCallback, showSuccessNoti } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";
import { Action } from "redux";

const token = () => `Bearer ${GetAuthToken()}`;

export const Login = (
  payload: LoginPayload
): Promise<APIResponse<LoginResponse>> =>
  request
    .post(`${apiURL}/Register/adminlogin/`, payload)
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getCustomers = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<CustomerListResponse[]>>> =>
  request
    .post(`${apiURL}/Customer/get/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getCustomer = (
  ID: string
): Promise<APIResponse<SingleCustomerResponse>> =>
  request
    .get(`${apiURL}/Customer/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveCustomer = (
  payload: CustomerPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Customer/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteCustomer = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Customer/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getPackages = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<PackagesListResponse[]>>> =>
  request
    .post(`${apiURL}/Package/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getPackage = (ID: string): Promise<APIResponse<Package>> =>
  request
    .get(`${apiURL}/Package/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const savePackage = (
  payload: PackagePayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Package/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deletePackage = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Package/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getBranches = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<BranchListResponse[]>>> =>
  request
    .post(`${apiURL}/Branche/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getBranch = (ID: string): Promise<APIResponse<Branch>> =>
  request
    .get(`${apiURL}/Branche/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveBranch = (
  payload: BranchPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Branche/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteBranch = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Branche/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getSections = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<SectionListResponse[]>>> =>
  request
    .post(`${apiURL}/Section/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getSection = (ID: string): Promise<APIResponse<Section>> =>
  request
    .get(`${apiURL}/Section/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveSection = (
  payload: SectionPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Section/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteSection = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Section/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getSubSections = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<SubSectionListResponse[]>>> =>
  request
    .post(`${apiURL}/SubSection/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => {
      errorCallback(err.response)
    });

export const getSubSection = (ID: string): Promise<APIResponse<SubSection>> =>
  request
    .get(`${apiURL}/SubSection/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveSubSection = (
  payload: SubSectionPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/SubSection/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteSubSection = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/SubSection/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));


    export const exportSubSections = (): Promise<any> =>
  request
    .get(`${apiURL}/SubSection/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-SubSection-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const getAssets = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<AssetListResponse[]>>> =>
  request
    .post(`${apiURL}/Asset/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getAsset = (ID: string): Promise<APIResponse<Asset>> =>
  request
    .get(`${apiURL}/Asset/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exportAssets = (): Promise<any> =>
  request
    .get(`${apiURL}/Asset/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-Asset-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const saveAsset = (
  payload: AssetPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Asset/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteAsset = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Asset/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getEquipments = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<EquipmentListResponse[]>>> =>
  request
    .post(`${apiURL}/Equipment/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const exportEquipments = (): Promise<any> =>
  request
    .get(`${apiURL}/Equipment/export`)
    .responseType("blob")
    .set("Authorization", token())
    .then((r: { body: any }) => {
      const url = window.URL.createObjectURL(new Blob([r.body]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TPM-Equipment-Dump.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => errorCallback(err.response));

export const getEquipment = (ID: string): Promise<APIResponse<Equipment>> =>
  request
    .get(`${apiURL}/Equipment/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveEquipment = (
  payload: EquipmentPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Equipment/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteEquipment = (ID: string, recursive: boolean): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Equipment/controllactivation/${ID}/${recursive}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getCustomerSubscriptions = (
  payload: PaginatedListPayload
): Promise<
  APIResponse<PaginatedListResponse<CustomerSubscriptionListResponse[]>>
> =>
  request
    .post(`${apiURL}/CustomerSubscription/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getUserSubscriptions = (): Promise<APIResponse<any[]>> =>
  request
    .get(`${apiURL}/CustomerSubscription/getusersubscriptions`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getCustomerSubscription = (
  ID: string
): Promise<APIResponse<CustomerSubscription>> =>
  request
    .get(`${apiURL}/CustomerSubscription/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveCustomerSubscription = (
  payload: CustomerSubscription
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/CustomerSubscription/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteCustomerSubscription = (
  ID: string
): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/CustomerSubscription/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getTeam = (ID: string): Promise<APIResponse<TeamPayload>> =>
  request
    .get(`${apiURL}/Teams/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const deleteTeam = (
  ID: string
): Promise<APIResponse<TeamPayload>> =>
  request
    .get(`${apiURL}/Teams/controllactivation/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getTeams = (payload: PaginatedListPayload):
Promise<APIResponse<Team>> =>
      request
        .post(`${apiURL}/Teams/get`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => {
          errorCallback(err.response);
        });
    
export const saveTeams = (payload: TeamPayload): 
Promise<APIResponse<boolean>> =>
      request
        .post(`${apiURL}/Teams/save`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => {
          showSuccessNoti();
          return r.body;
        })
        .catch((err) => {
          errorCallback(err.response);
        });
            
export const getConsiderations = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<ConsiderationListResponse[]>>> =>
  request
    .post(`${apiURL}/Considerations/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getConsideration = (ID: string): Promise<APIResponse<ConsiderationListResponse>> =>
  request
    .get(`${apiURL}/Considerations/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveConsideration = (
  payload: ConsideratinPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Considerations/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteConsideration = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Considerations/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getMandates = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<MandatesListResponse[]>>> =>
  request
    .post(`${apiURL}/Maindates/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMandate= (ID: string): Promise<APIResponse<MandatesListResponse>> =>
  request
    .get(`${apiURL}/Maindates/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveMandate = (
  payload: MandatesPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Maindates/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteMandate = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Maindates/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));
 
export const getActions = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<ActionsListResponse[]>>> =>
  request
    .post(`${apiURL}/MocActions/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getAction = (ID: string): Promise<APIResponse<ActionsListResponse>> =>
  request
    .get(`${apiURL}/MocActions/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveAction = (
  payload: ActionsPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/MocActions/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteAction = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/MocActions/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));
 
export const getEquipmentTypes = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<EquipmentTypesListResponse[]>>> =>
  request
    .post(`${apiURL}/EquipmentTypes/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getEquipmentType = (ID: string): Promise<APIResponse<EquipmentTypesListResponse>> =>
  request
    .get(`${apiURL}/EquipmentTypes/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveEquipmentType = (
  payload: EquipmentTypesPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/EquipmentTypes/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteEquipmentType = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/EquipmentTypes/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getDepartments = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<DepartmentListResponse[]>>> =>
  request
    .post(`${apiURL}/Departments/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getDepartment = (ID: string): Promise<APIResponse<DepartmentListResponse>> =>
  request
    .get(`${apiURL}/Departments/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveDepartment = (
  payload: DepartmentPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Departments/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteDepartment = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Departments/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));
 
export const getMocCategories = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<MocCategoryListResponse[]>>> =>
  request
    .post(`${apiURL}/MocChangeCategory/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMocCategory= (ID: string): Promise<APIResponse<MocCategoryListResponse>> =>
  request
    .get(`${apiURL}/MocChangeCategory/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveMocCategory = (
  payload: MocCategoryPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/MocChangeCategory/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteMocCategory = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/MocChangeCategory/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getMocs = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<MocListResponse[]>>> =>
  request
    .post(`${apiURL}/Moc/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMocDetail = (
  ID: string
): Promise<APIResponse<PaginatedListResponse<MocListResponse[]>>> =>
  request
    .post(`${apiURL}/Moc/getdetail/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMoc = (ID: string): Promise<APIResponse<MocListResponse>> =>
  request
    .get(`${apiURL}/Moc/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveMoc = (
  payload: MocPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Moc/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteMoc = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Moc/controllactivation/${ID}/true`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));
   
export const getMocConsiderationActions = (
  payload: ConfigPaginatedListPayload & {mocId?: string}
): Promise<APIResponse<PaginatedListResponse<MocConsidrationsActionsResponse[]>>> =>
  request
    .post(`${apiURL}/MocConsidrationsActions/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMocConsiderationAction = (ID: string): Promise<APIResponse<MocConsidrationsActionsResponse>> =>
  request
    .get(`${apiURL}/MocConsidrationsActions/getedit/${ID}/`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveMocConsiderationAction = (
  payload: MocConsidrationsActionsPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/MocConsidrationsActions/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteMocConsiderationAction = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/MocConsidrationsActions/controllactivation/${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const saveMocCaonsiderationRemarks = (
  payload: MocConsiderationRemarksPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/MocConsidrationsRemarks/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getMocCaonsiderationRemarks = (
  payload: ConfigPaginatedListPayload
): Promise<
  APIResponse<PaginatedListResponse<MocCaonsiderationRemarksListResponse[]>>
> =>
  request
    .post(`${apiURL}/MocConsidrationsRemarks/get`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMocsApprovers = (
  payload: ConfigPaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<MocApprovalsResponse[]>>> =>
  request
    .post(`${apiURL}/Moc/getmocapprovers`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveMocConsidrationMedia = (
  payload: MocConsidrationMediaPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/MocConsidrationsMedia/save/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getMocConsiderationsMedias = (
      payload: ConfigPaginatedListPayload & {mediaTypeId?: string}
    ): Promise<APIResponse<PaginatedListResponse<MocConsidrationMediaResponse[]>>> =>
      request
        .post(`${apiURL}/MocConsidrationsMedia/get`, payload)
        .set("Authorization", token())
        .then((r: { body: any }) => r.body)
        .catch((err) => errorCallback(err.response));

/////////////////////// Validate //////////////////////////////////////
export const mocApprovers = (mocId: string, approvelTypeId: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Moc/mocapprovers?mocId=${mocId}&approvalTypeId=${approvelTypeId}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const changeMocStatus = (mocId: string, statusId: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Moc/changemocstatus?mocId=${mocId}&StatusID=${statusId}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const MarkAsCompleted = (mocConsidrationId: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/MocConsidrationsActions/markascomleted/${mocConsidrationId}`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const ChangeMocConsidrationStatus = (payload: ChangeMocConsidrationStatusPayload): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Moc/changemocconsiderationsstatus?MocConsiderationsId=${payload.MocConsiderationsId}&StatusID=${payload.StatusID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));
/////////////////////// Accounts TABLES //////////////////////////////////////

export const getUsers = (
  payload: PaginatedListPayload
): Promise<APIResponse<PaginatedListResponse<AppUser[]>>> =>
  request
    .post(`${apiURL}/Register/getusers`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getUser = (ID: string): Promise<APIResponse<AppUser>> =>
  request
    .get(`${apiURL}/Register/getedituser/${ID}?userId=${ID}`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const saveUser = (payload: AppUser): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Register/saveuser/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const deleteUser = (ID: string): Promise<APIResponse<boolean>> =>
  request
    .get(`${apiURL}/Register/controlluseractivation/${ID}/false/true`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const changeActiveUserStatus = (
  ID: string,
  isActive: boolean
): Promise<APIResponse<boolean>> =>
  request
    .get(
      `${apiURL}/Register/controllactivation/${ID}/${isActive.toString()}/false`
    )
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const changePassword = (
  payload: ChangePasswordPayload
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Register/changepassword`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getNotifications = (): Promise<APIResponse<TpmNotification[]>> =>
  request
    .get(`${apiURL}/Register/notifications`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const getUserNotifications = (): Promise<
  APIResponse<TpmUserNotification[]>
> =>
  request
    .get(`${apiURL}/Register/UserNotifications`)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const updateUserNotifications = (
  payload: string[]
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/Register/UpdateUserNotifications`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => {
      showSuccessNoti();
      return r.body;
    })
    .catch((err) => errorCallback(err.response));

export const uploadMedia = (payload: FormData): Promise<APIResponse<string>> =>
  request
    .post(`${apiURL}/Upload/uploadmedia`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

    /////////////////////// Email Verification //////////////////////////////////////

    export const SendEmailVerification = (): Promise<APIResponse<boolean>> =>
      request
        .get(`${apiURL}/Register/SendEmailVerification`)
        .set("Authorization", token())
        .then((r: { body: any }) => {
          showSuccessNoti();
          return r.body;
        })
        .catch((err) => errorCallback(err.response));

        export const VerifyEmail = (
          secret: string,
        ): Promise<APIResponse<boolean>> =>
          request
            .post(`${apiURL}/Register/VerifyEmail/${secret}`, {})
            .set("Authorization", token())
            .then((r: { body: any }) => {
              showSuccessNoti();
              return r.body;
            })
            .catch((err) => errorCallback(err.response));