import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getEquipmentTypes = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getequipmenttypes`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getIpFaculties = (): Promise<APIResponse<MasterListResponse[]>> =>
  request
    .get(`${apiURL}/MasterTable/getfaculty`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));
    
export const getStandingStatus = (): Promise<
APIResponse<MasterListResponse[]>
> =>
request
  .get(`${apiURL}/MasterTable/getstandingstatus`)
  .set("Authorization", token())
  .then((r: { body: any }) => r.body)
  .catch((err) => errorCallback(err.response));

export const getChangeType = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getchangetype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getApprovalType = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getapprovaltype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getConsiderationType = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getconsiderationtype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getMocActionsType = (): Promise<
  APIResponse<MasterListResponse[]>
> =>
  request
    .get(`${apiURL}/MasterTable/getmocactionstype`)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));